var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" History Progress & Tracker ")]), _c('c-text', {
    attrs: {
      "mt": "30px"
    }
  }, [_vm._v(" History Progress & Tracker ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "flex-wrap": "wrap",
      "grid-gap": "20px"
    }
  }, _vm._l(_vm.history, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "rounded": "lg",
        "border-width": "1px",
        "border-color": "primary.400",
        "p": "20px",
        "w": "386px",
        "h": "112px",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "flex": "1"
      }
    }, [_c('c-text', [_vm._v(" History Progress " + _vm._s(index))]), _c('c-text', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.date, "DD MMM YYYY")))])], 1), _c('FlatButton', {
      on: {
        "click": function click($event) {
          return _vm.onDetailClick(item.id);
        }
      }
    }, [_c('img', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "src": require('@/assets/icon-chevron-right.svg'),
        "alt": "icon chevron",
        "h": "40px",
        "w": "40px"
      }
    })])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }