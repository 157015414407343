<template>
  <c-box>
    <c-text font-size="2xl">
      History Progress &amp; Tracker
    </c-text>
    <c-text mt="30px">
      History Progress &amp; Tracker
    </c-text>
    <hr
      v-chakra
      w="full"
    >
    <c-flex
      mt="20px"
      flex-wrap="wrap"
      grid-gap="20px"
    >
      <c-flex
        v-for="(item, index) in history"
        :key="index"
        rounded="lg"
        border-width="1px"
        border-color="primary.400"
        p="20px"
        w="386px"
        h="112px"
        align-items="center"
      >
        <c-box flex="1">
          <c-text> History Progress {{ index }}</c-text>
          <c-text> {{ item.date | formatDate("DD MMM YYYY") }}</c-text>
        </c-box>
        <FlatButton @click="onDetailClick(item.id)">
          <img
            v-chakra
            :src="require('@/assets/icon-chevron-right.svg')"
            alt="icon chevron"
            h="40px"
            w="40px"
          >
        </FlatButton>
      </c-flex>
    </c-flex>
  </c-box>
</template>



<script >
import FlatButton from '@/components/flat-button.vue';
import { onMounted, ref } from '@vue/composition-api';
import { useActions } from '@/stores';
import { useRouter } from 'vue2-helpers/vue-router';
const __sfc_main = {
  name: 'HistoryProgressTracker'
};

__sfc_main.setup = (__props, __ctx) => {
  const $router = useRouter();
  let history = ref([]);
  const {
    getHistory
  } = useActions('clientProgressTracker', ['getHistory']);
  onMounted(async () => {
    let data = await getHistory();
    history.value = data;
  });

  function onDetailClick(id) {
    $router.push({
      name: 'client.progress-tracker.detail',
      params: {
        progressId: id
      }
    });
  }

  return {
    history,
    onDetailClick
  };
};

__sfc_main.components = Object.assign({
  FlatButton
}, __sfc_main.components);
export default __sfc_main;
</script>
